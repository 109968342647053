.panelHolder {
    padding: 20px;
}

span.formLabel {
    width: 100px;
    height: 20px;
    display: block;
    float: left;
}

div.halText {
    color: #bbc;
}